/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateProductFinderRequestInput = {
  id?: string | null,
  user_id: string,
  status: string,
  image_url: string,
  result?: string | null,
  createdAt?: string | null,
};

export type ModelProductFinderRequestConditionInput = {
  user_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  result?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductFinderRequestConditionInput | null > | null,
  or?: Array< ModelProductFinderRequestConditionInput | null > | null,
  not?: ModelProductFinderRequestConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ProductFinderRequest = {
  __typename: "ProductFinderRequest",
  id: string,
  user_id: string,
  status: string,
  image_url: string,
  result?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateProductFinderRequestInput = {
  id: string,
  user_id?: string | null,
  status?: string | null,
  image_url?: string | null,
  result?: string | null,
  createdAt?: string | null,
};

export type DeleteProductFinderRequestInput = {
  id: string,
};

export type CreateProductIdForUrlInput = {
  url: string,
  product_id: string,
};

export type ModelProductIdForUrlConditionInput = {
  product_id?: ModelIDInput | null,
  and?: Array< ModelProductIdForUrlConditionInput | null > | null,
  or?: Array< ModelProductIdForUrlConditionInput | null > | null,
  not?: ModelProductIdForUrlConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ProductIdForUrl = {
  __typename: "ProductIdForUrl",
  url: string,
  product_id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateProductIdForUrlInput = {
  url: string,
  product_id?: string | null,
};

export type DeleteProductIdForUrlInput = {
  url: string,
};

export type CreateSavedProductInput = {
  user_id: string,
  product_id: string,
  createdAt?: string | null,
};

export type ModelSavedProductConditionInput = {
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSavedProductConditionInput | null > | null,
  or?: Array< ModelSavedProductConditionInput | null > | null,
  not?: ModelSavedProductConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type SavedProduct = {
  __typename: "SavedProduct",
  user_id: string,
  product_id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSavedProductInput = {
  user_id: string,
  product_id: string,
  createdAt?: string | null,
};

export type DeleteSavedProductInput = {
  user_id: string,
  product_id: string,
};

export type CreateVisionSearchEntryInput = {
  id?: string | null,
  user_id: string,
  image_url: string,
  result?: string | null,
  createdAt?: string | null,
};

export type ModelVisionSearchEntryConditionInput = {
  user_id?: ModelIDInput | null,
  image_url?: ModelStringInput | null,
  result?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelVisionSearchEntryConditionInput | null > | null,
  or?: Array< ModelVisionSearchEntryConditionInput | null > | null,
  not?: ModelVisionSearchEntryConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type VisionSearchEntry = {
  __typename: "VisionSearchEntry",
  id: string,
  user_id: string,
  image_url: string,
  result?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVisionSearchEntryInput = {
  id: string,
  user_id?: string | null,
  image_url?: string | null,
  result?: string | null,
  createdAt?: string | null,
};

export type DeleteVisionSearchEntryInput = {
  id: string,
};

export type CreateStylistRoomInput = {
  id?: string | null,
  name: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  modelBuild?: string | null,
  userStatus?: string | null,
};

export type ModelStylistRoomConditionInput = {
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  modelBuild?: ModelStringInput | null,
  userStatus?: ModelStringInput | null,
  and?: Array< ModelStylistRoomConditionInput | null > | null,
  or?: Array< ModelStylistRoomConditionInput | null > | null,
  not?: ModelStylistRoomConditionInput | null,
};

export type StylistRoom = {
  __typename: "StylistRoom",
  id: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  users?: ModelUserStylistRoomConnection | null,
  entries?: ModelStylistRoomEntryConnection | null,
  modelBuild?: string | null,
  userStatus?: string | null,
};

export type ModelUserStylistRoomConnection = {
  __typename: "ModelUserStylistRoomConnection",
  items:  Array<UserStylistRoom | null >,
  nextToken?: string | null,
};

export type UserStylistRoom = {
  __typename: "UserStylistRoom",
  id: string,
  userId: string,
  stylistRoomId: string,
  joinedAt: string,
  role?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelStylistRoomEntryConnection = {
  __typename: "ModelStylistRoomEntryConnection",
  items:  Array<StylistRoomEntry | null >,
  nextToken?: string | null,
};

export type StylistRoomEntry = {
  __typename: "StylistRoomEntry",
  id: string,
  stylistRoomId: string,
  senderId: string,
  content: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateStylistRoomInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  modelBuild?: string | null,
  userStatus?: string | null,
};

export type DeleteStylistRoomInput = {
  id: string,
};

export type CreateUserStylistRoomInput = {
  id?: string | null,
  userId: string,
  stylistRoomId: string,
  joinedAt: string,
  role?: string | null,
};

export type ModelUserStylistRoomConditionInput = {
  userId?: ModelIDInput | null,
  stylistRoomId?: ModelIDInput | null,
  joinedAt?: ModelStringInput | null,
  role?: ModelStringInput | null,
  and?: Array< ModelUserStylistRoomConditionInput | null > | null,
  or?: Array< ModelUserStylistRoomConditionInput | null > | null,
  not?: ModelUserStylistRoomConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserStylistRoomInput = {
  id: string,
  userId?: string | null,
  stylistRoomId?: string | null,
  joinedAt?: string | null,
  role?: string | null,
};

export type DeleteUserStylistRoomInput = {
  id: string,
};

export type CreateStylistRoomEntryInput = {
  id?: string | null,
  stylistRoomId: string,
  senderId: string,
  content: string,
  createdAt?: string | null,
};

export type ModelStylistRoomEntryConditionInput = {
  stylistRoomId?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelStylistRoomEntryConditionInput | null > | null,
  or?: Array< ModelStylistRoomEntryConditionInput | null > | null,
  not?: ModelStylistRoomEntryConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateStylistRoomEntryInput = {
  id: string,
  stylistRoomId?: string | null,
  senderId?: string | null,
  content?: string | null,
  createdAt?: string | null,
};

export type DeleteStylistRoomEntryInput = {
  id: string,
};

export type ModelProductFinderRequestFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  image_url?: ModelStringInput | null,
  result?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductFinderRequestFilterInput | null > | null,
  or?: Array< ModelProductFinderRequestFilterInput | null > | null,
  not?: ModelProductFinderRequestFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelProductFinderRequestConnection = {
  __typename: "ModelProductFinderRequestConnection",
  items:  Array<ProductFinderRequest | null >,
  nextToken?: string | null,
};

export type ModelProductIdForUrlFilterInput = {
  url?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductIdForUrlFilterInput | null > | null,
  or?: Array< ModelProductIdForUrlFilterInput | null > | null,
  not?: ModelProductIdForUrlFilterInput | null,
};

export type ModelProductIdForUrlConnection = {
  __typename: "ModelProductIdForUrlConnection",
  items:  Array<ProductIdForUrl | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSavedProductFilterInput = {
  user_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSavedProductFilterInput | null > | null,
  or?: Array< ModelSavedProductFilterInput | null > | null,
  not?: ModelSavedProductFilterInput | null,
};

export type ModelSavedProductConnection = {
  __typename: "ModelSavedProductConnection",
  items:  Array<SavedProduct | null >,
  nextToken?: string | null,
};

export type ModelVisionSearchEntryFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  image_url?: ModelStringInput | null,
  result?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVisionSearchEntryFilterInput | null > | null,
  or?: Array< ModelVisionSearchEntryFilterInput | null > | null,
  not?: ModelVisionSearchEntryFilterInput | null,
};

export type ModelVisionSearchEntryConnection = {
  __typename: "ModelVisionSearchEntryConnection",
  items:  Array<VisionSearchEntry | null >,
  nextToken?: string | null,
};

export type ModelStylistRoomFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  modelBuild?: ModelStringInput | null,
  userStatus?: ModelStringInput | null,
  and?: Array< ModelStylistRoomFilterInput | null > | null,
  or?: Array< ModelStylistRoomFilterInput | null > | null,
  not?: ModelStylistRoomFilterInput | null,
};

export type ModelStylistRoomConnection = {
  __typename: "ModelStylistRoomConnection",
  items:  Array<StylistRoom | null >,
  nextToken?: string | null,
};

export type ModelUserStylistRoomFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  stylistRoomId?: ModelIDInput | null,
  joinedAt?: ModelStringInput | null,
  role?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserStylistRoomFilterInput | null > | null,
  or?: Array< ModelUserStylistRoomFilterInput | null > | null,
  not?: ModelUserStylistRoomFilterInput | null,
};

export type ModelStylistRoomEntryFilterInput = {
  id?: ModelIDInput | null,
  stylistRoomId?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStylistRoomEntryFilterInput | null > | null,
  or?: Array< ModelStylistRoomEntryFilterInput | null > | null,
  not?: ModelStylistRoomEntryFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSubscriptionProductFinderRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  image_url?: ModelSubscriptionStringInput | null,
  result?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFinderRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFinderRequestFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionProductIdForUrlFilterInput = {
  url?: ModelSubscriptionIDInput | null,
  product_id?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductIdForUrlFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductIdForUrlFilterInput | null > | null,
};

export type ModelSubscriptionSavedProductFilterInput = {
  user_id?: ModelSubscriptionIDInput | null,
  product_id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSavedProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionSavedProductFilterInput | null > | null,
};

export type ModelSubscriptionVisionSearchEntryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  image_url?: ModelSubscriptionStringInput | null,
  result?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVisionSearchEntryFilterInput | null > | null,
  or?: Array< ModelSubscriptionVisionSearchEntryFilterInput | null > | null,
};

export type ModelSubscriptionStylistRoomFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  modelBuild?: ModelSubscriptionStringInput | null,
  userStatus?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStylistRoomFilterInput | null > | null,
  or?: Array< ModelSubscriptionStylistRoomFilterInput | null > | null,
};

export type ModelSubscriptionUserStylistRoomFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  stylistRoomId?: ModelSubscriptionIDInput | null,
  joinedAt?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserStylistRoomFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserStylistRoomFilterInput | null > | null,
};

export type ModelSubscriptionStylistRoomEntryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  stylistRoomId?: ModelSubscriptionIDInput | null,
  senderId?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStylistRoomEntryFilterInput | null > | null,
  or?: Array< ModelSubscriptionStylistRoomEntryFilterInput | null > | null,
};

export type CreateProductFinderRequestMutationVariables = {
  input: CreateProductFinderRequestInput,
  condition?: ModelProductFinderRequestConditionInput | null,
};

export type CreateProductFinderRequestMutation = {
  createProductFinderRequest?:  {
    __typename: "ProductFinderRequest",
    id: string,
    user_id: string,
    status: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductFinderRequestMutationVariables = {
  input: UpdateProductFinderRequestInput,
  condition?: ModelProductFinderRequestConditionInput | null,
};

export type UpdateProductFinderRequestMutation = {
  updateProductFinderRequest?:  {
    __typename: "ProductFinderRequest",
    id: string,
    user_id: string,
    status: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductFinderRequestMutationVariables = {
  input: DeleteProductFinderRequestInput,
  condition?: ModelProductFinderRequestConditionInput | null,
};

export type DeleteProductFinderRequestMutation = {
  deleteProductFinderRequest?:  {
    __typename: "ProductFinderRequest",
    id: string,
    user_id: string,
    status: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductIdForUrlMutationVariables = {
  input: CreateProductIdForUrlInput,
  condition?: ModelProductIdForUrlConditionInput | null,
};

export type CreateProductIdForUrlMutation = {
  createProductIdForUrl?:  {
    __typename: "ProductIdForUrl",
    url: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductIdForUrlMutationVariables = {
  input: UpdateProductIdForUrlInput,
  condition?: ModelProductIdForUrlConditionInput | null,
};

export type UpdateProductIdForUrlMutation = {
  updateProductIdForUrl?:  {
    __typename: "ProductIdForUrl",
    url: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductIdForUrlMutationVariables = {
  input: DeleteProductIdForUrlInput,
  condition?: ModelProductIdForUrlConditionInput | null,
};

export type DeleteProductIdForUrlMutation = {
  deleteProductIdForUrl?:  {
    __typename: "ProductIdForUrl",
    url: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSavedProductMutationVariables = {
  input: CreateSavedProductInput,
  condition?: ModelSavedProductConditionInput | null,
};

export type CreateSavedProductMutation = {
  createSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSavedProductMutationVariables = {
  input: UpdateSavedProductInput,
  condition?: ModelSavedProductConditionInput | null,
};

export type UpdateSavedProductMutation = {
  updateSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSavedProductMutationVariables = {
  input: DeleteSavedProductInput,
  condition?: ModelSavedProductConditionInput | null,
};

export type DeleteSavedProductMutation = {
  deleteSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVisionSearchEntryMutationVariables = {
  input: CreateVisionSearchEntryInput,
  condition?: ModelVisionSearchEntryConditionInput | null,
};

export type CreateVisionSearchEntryMutation = {
  createVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVisionSearchEntryMutationVariables = {
  input: UpdateVisionSearchEntryInput,
  condition?: ModelVisionSearchEntryConditionInput | null,
};

export type UpdateVisionSearchEntryMutation = {
  updateVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVisionSearchEntryMutationVariables = {
  input: DeleteVisionSearchEntryInput,
  condition?: ModelVisionSearchEntryConditionInput | null,
};

export type DeleteVisionSearchEntryMutation = {
  deleteVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStylistRoomMutationVariables = {
  input: CreateStylistRoomInput,
  condition?: ModelStylistRoomConditionInput | null,
};

export type CreateStylistRoomMutation = {
  createStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserStylistRoomConnection",
      nextToken?: string | null,
    } | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type UpdateStylistRoomMutationVariables = {
  input: UpdateStylistRoomInput,
  condition?: ModelStylistRoomConditionInput | null,
};

export type UpdateStylistRoomMutation = {
  updateStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserStylistRoomConnection",
      nextToken?: string | null,
    } | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type DeleteStylistRoomMutationVariables = {
  input: DeleteStylistRoomInput,
  condition?: ModelStylistRoomConditionInput | null,
};

export type DeleteStylistRoomMutation = {
  deleteStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserStylistRoomConnection",
      nextToken?: string | null,
    } | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type CreateUserStylistRoomMutationVariables = {
  input: CreateUserStylistRoomInput,
  condition?: ModelUserStylistRoomConditionInput | null,
};

export type CreateUserStylistRoomMutation = {
  createUserStylistRoom?:  {
    __typename: "UserStylistRoom",
    id: string,
    userId: string,
    stylistRoomId: string,
    joinedAt: string,
    role?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserStylistRoomMutationVariables = {
  input: UpdateUserStylistRoomInput,
  condition?: ModelUserStylistRoomConditionInput | null,
};

export type UpdateUserStylistRoomMutation = {
  updateUserStylistRoom?:  {
    __typename: "UserStylistRoom",
    id: string,
    userId: string,
    stylistRoomId: string,
    joinedAt: string,
    role?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserStylistRoomMutationVariables = {
  input: DeleteUserStylistRoomInput,
  condition?: ModelUserStylistRoomConditionInput | null,
};

export type DeleteUserStylistRoomMutation = {
  deleteUserStylistRoom?:  {
    __typename: "UserStylistRoom",
    id: string,
    userId: string,
    stylistRoomId: string,
    joinedAt: string,
    role?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStylistRoomEntryMutationVariables = {
  input: CreateStylistRoomEntryInput,
  condition?: ModelStylistRoomEntryConditionInput | null,
};

export type CreateStylistRoomEntryMutation = {
  createStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStylistRoomEntryMutationVariables = {
  input: UpdateStylistRoomEntryInput,
  condition?: ModelStylistRoomEntryConditionInput | null,
};

export type UpdateStylistRoomEntryMutation = {
  updateStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStylistRoomEntryMutationVariables = {
  input: DeleteStylistRoomEntryInput,
  condition?: ModelStylistRoomEntryConditionInput | null,
};

export type DeleteStylistRoomEntryMutation = {
  deleteStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetProductFinderRequestQueryVariables = {
  id: string,
};

export type GetProductFinderRequestQuery = {
  getProductFinderRequest?:  {
    __typename: "ProductFinderRequest",
    id: string,
    user_id: string,
    status: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductFinderRequestsQueryVariables = {
  id?: string | null,
  filter?: ModelProductFinderRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductFinderRequestsQuery = {
  listProductFinderRequests?:  {
    __typename: "ModelProductFinderRequestConnection",
    items:  Array< {
      __typename: "ProductFinderRequest",
      id: string,
      user_id: string,
      status: string,
      image_url: string,
      result?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductIdForUrlQueryVariables = {
  url: string,
};

export type GetProductIdForUrlQuery = {
  getProductIdForUrl?:  {
    __typename: "ProductIdForUrl",
    url: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductIdForUrlsQueryVariables = {
  url?: string | null,
  filter?: ModelProductIdForUrlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductIdForUrlsQuery = {
  listProductIdForUrls?:  {
    __typename: "ModelProductIdForUrlConnection",
    items:  Array< {
      __typename: "ProductIdForUrl",
      url: string,
      product_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSavedProductQueryVariables = {
  user_id: string,
  product_id: string,
};

export type GetSavedProductQuery = {
  getSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSavedProductsQueryVariables = {
  user_id?: string | null,
  product_id?: ModelIDKeyConditionInput | null,
  filter?: ModelSavedProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSavedProductsQuery = {
  listSavedProducts?:  {
    __typename: "ModelSavedProductConnection",
    items:  Array< {
      __typename: "SavedProduct",
      user_id: string,
      product_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVisionSearchEntryQueryVariables = {
  id: string,
};

export type GetVisionSearchEntryQuery = {
  getVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVisionSearchEntriesQueryVariables = {
  id?: string | null,
  filter?: ModelVisionSearchEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListVisionSearchEntriesQuery = {
  listVisionSearchEntries?:  {
    __typename: "ModelVisionSearchEntryConnection",
    items:  Array< {
      __typename: "VisionSearchEntry",
      id: string,
      user_id: string,
      image_url: string,
      result?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStylistRoomQueryVariables = {
  id: string,
};

export type GetStylistRoomQuery = {
  getStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserStylistRoomConnection",
      nextToken?: string | null,
    } | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type ListStylistRoomsQueryVariables = {
  id?: string | null,
  filter?: ModelStylistRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStylistRoomsQuery = {
  listStylistRooms?:  {
    __typename: "ModelStylistRoomConnection",
    items:  Array< {
      __typename: "StylistRoom",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      modelBuild?: string | null,
      userStatus?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserStylistRoomQueryVariables = {
  id: string,
};

export type GetUserStylistRoomQuery = {
  getUserStylistRoom?:  {
    __typename: "UserStylistRoom",
    id: string,
    userId: string,
    stylistRoomId: string,
    joinedAt: string,
    role?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserStylistRoomsQueryVariables = {
  id?: string | null,
  filter?: ModelUserStylistRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserStylistRoomsQuery = {
  listUserStylistRooms?:  {
    __typename: "ModelUserStylistRoomConnection",
    items:  Array< {
      __typename: "UserStylistRoom",
      id: string,
      userId: string,
      stylistRoomId: string,
      joinedAt: string,
      role?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStylistRoomEntryQueryVariables = {
  id: string,
};

export type GetStylistRoomEntryQuery = {
  getStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStylistRoomEntriesQueryVariables = {
  id?: string | null,
  filter?: ModelStylistRoomEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStylistRoomEntriesQuery = {
  listStylistRoomEntries?:  {
    __typename: "ModelStylistRoomEntryConnection",
    items:  Array< {
      __typename: "StylistRoomEntry",
      id: string,
      stylistRoomId: string,
      senderId: string,
      content: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductFinderRequestsByUserIdQueryVariables = {
  user_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFinderRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductFinderRequestsByUserIdQuery = {
  productFinderRequestsByUserId?:  {
    __typename: "ModelProductFinderRequestConnection",
    items:  Array< {
      __typename: "ProductFinderRequest",
      id: string,
      user_id: string,
      status: string,
      image_url: string,
      result?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UrlsByProductIdQueryVariables = {
  product_id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductIdForUrlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UrlsByProductIdQuery = {
  urlsByProductId?:  {
    __typename: "ModelProductIdForUrlConnection",
    items:  Array< {
      __typename: "ProductIdForUrl",
      url: string,
      product_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SavedProductsByUserQueryVariables = {
  user_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSavedProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SavedProductsByUserQuery = {
  savedProductsByUser?:  {
    __typename: "ModelSavedProductConnection",
    items:  Array< {
      __typename: "SavedProduct",
      user_id: string,
      product_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SavesForProductQueryVariables = {
  product_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSavedProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SavesForProductQuery = {
  savesForProduct?:  {
    __typename: "ModelSavedProductConnection",
    items:  Array< {
      __typename: "SavedProduct",
      user_id: string,
      product_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VisionSearchEntriesByUserIdQueryVariables = {
  user_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVisionSearchEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VisionSearchEntriesByUserIdQuery = {
  visionSearchEntriesByUserId?:  {
    __typename: "ModelVisionSearchEntryConnection",
    items:  Array< {
      __typename: "VisionSearchEntry",
      id: string,
      user_id: string,
      image_url: string,
      result?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserStylistRoomsByUserIdAndStylistRoomIdQueryVariables = {
  userId: string,
  stylistRoomId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserStylistRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserStylistRoomsByUserIdAndStylistRoomIdQuery = {
  userStylistRoomsByUserIdAndStylistRoomId?:  {
    __typename: "ModelUserStylistRoomConnection",
    items:  Array< {
      __typename: "UserStylistRoom",
      id: string,
      userId: string,
      stylistRoomId: string,
      joinedAt: string,
      role?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserStylistRoomsByStylistRoomIdAndUserIdQueryVariables = {
  stylistRoomId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserStylistRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserStylistRoomsByStylistRoomIdAndUserIdQuery = {
  userStylistRoomsByStylistRoomIdAndUserId?:  {
    __typename: "ModelUserStylistRoomConnection",
    items:  Array< {
      __typename: "UserStylistRoom",
      id: string,
      userId: string,
      stylistRoomId: string,
      joinedAt: string,
      role?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StylistRoomEntriesByStylistRoomIdQueryVariables = {
  stylistRoomId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStylistRoomEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StylistRoomEntriesByStylistRoomIdQuery = {
  stylistRoomEntriesByStylistRoomId?:  {
    __typename: "ModelStylistRoomEntryConnection",
    items:  Array< {
      __typename: "StylistRoomEntry",
      id: string,
      stylistRoomId: string,
      senderId: string,
      content: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateProductFinderRequestSubscriptionVariables = {
  filter?: ModelSubscriptionProductFinderRequestFilterInput | null,
};

export type OnCreateProductFinderRequestSubscription = {
  onCreateProductFinderRequest?:  {
    __typename: "ProductFinderRequest",
    id: string,
    user_id: string,
    status: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductFinderRequestSubscriptionVariables = {
  filter?: ModelSubscriptionProductFinderRequestFilterInput | null,
};

export type OnUpdateProductFinderRequestSubscription = {
  onUpdateProductFinderRequest?:  {
    __typename: "ProductFinderRequest",
    id: string,
    user_id: string,
    status: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductFinderRequestSubscriptionVariables = {
  filter?: ModelSubscriptionProductFinderRequestFilterInput | null,
};

export type OnDeleteProductFinderRequestSubscription = {
  onDeleteProductFinderRequest?:  {
    __typename: "ProductFinderRequest",
    id: string,
    user_id: string,
    status: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductIdForUrlSubscriptionVariables = {
  filter?: ModelSubscriptionProductIdForUrlFilterInput | null,
};

export type OnCreateProductIdForUrlSubscription = {
  onCreateProductIdForUrl?:  {
    __typename: "ProductIdForUrl",
    url: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductIdForUrlSubscriptionVariables = {
  filter?: ModelSubscriptionProductIdForUrlFilterInput | null,
};

export type OnUpdateProductIdForUrlSubscription = {
  onUpdateProductIdForUrl?:  {
    __typename: "ProductIdForUrl",
    url: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductIdForUrlSubscriptionVariables = {
  filter?: ModelSubscriptionProductIdForUrlFilterInput | null,
};

export type OnDeleteProductIdForUrlSubscription = {
  onDeleteProductIdForUrl?:  {
    __typename: "ProductIdForUrl",
    url: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSavedProductSubscriptionVariables = {
  filter?: ModelSubscriptionSavedProductFilterInput | null,
};

export type OnCreateSavedProductSubscription = {
  onCreateSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSavedProductSubscriptionVariables = {
  filter?: ModelSubscriptionSavedProductFilterInput | null,
};

export type OnUpdateSavedProductSubscription = {
  onUpdateSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSavedProductSubscriptionVariables = {
  filter?: ModelSubscriptionSavedProductFilterInput | null,
};

export type OnDeleteSavedProductSubscription = {
  onDeleteSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVisionSearchEntrySubscriptionVariables = {
  filter?: ModelSubscriptionVisionSearchEntryFilterInput | null,
};

export type OnCreateVisionSearchEntrySubscription = {
  onCreateVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVisionSearchEntrySubscriptionVariables = {
  filter?: ModelSubscriptionVisionSearchEntryFilterInput | null,
};

export type OnUpdateVisionSearchEntrySubscription = {
  onUpdateVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVisionSearchEntrySubscriptionVariables = {
  filter?: ModelSubscriptionVisionSearchEntryFilterInput | null,
};

export type OnDeleteVisionSearchEntrySubscription = {
  onDeleteVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomFilterInput | null,
};

export type OnCreateStylistRoomSubscription = {
  onCreateStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserStylistRoomConnection",
      nextToken?: string | null,
    } | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type OnUpdateStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomFilterInput | null,
};

export type OnUpdateStylistRoomSubscription = {
  onUpdateStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserStylistRoomConnection",
      nextToken?: string | null,
    } | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type OnDeleteStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomFilterInput | null,
};

export type OnDeleteStylistRoomSubscription = {
  onDeleteStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?:  {
      __typename: "ModelUserStylistRoomConnection",
      nextToken?: string | null,
    } | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type OnCreateUserStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionUserStylistRoomFilterInput | null,
};

export type OnCreateUserStylistRoomSubscription = {
  onCreateUserStylistRoom?:  {
    __typename: "UserStylistRoom",
    id: string,
    userId: string,
    stylistRoomId: string,
    joinedAt: string,
    role?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionUserStylistRoomFilterInput | null,
};

export type OnUpdateUserStylistRoomSubscription = {
  onUpdateUserStylistRoom?:  {
    __typename: "UserStylistRoom",
    id: string,
    userId: string,
    stylistRoomId: string,
    joinedAt: string,
    role?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionUserStylistRoomFilterInput | null,
};

export type OnDeleteUserStylistRoomSubscription = {
  onDeleteUserStylistRoom?:  {
    __typename: "UserStylistRoom",
    id: string,
    userId: string,
    stylistRoomId: string,
    joinedAt: string,
    role?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStylistRoomEntrySubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomEntryFilterInput | null,
};

export type OnCreateStylistRoomEntrySubscription = {
  onCreateStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStylistRoomEntrySubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomEntryFilterInput | null,
};

export type OnUpdateStylistRoomEntrySubscription = {
  onUpdateStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStylistRoomEntrySubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomEntryFilterInput | null,
};

export type OnDeleteStylistRoomEntrySubscription = {
  onDeleteStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
