import {
  CreateSavedProductMutation,
  DeleteSavedProductMutation,
  ModelSortDirection,
  SavedProduct,
} from './types'
import {createSavedProduct, deleteSavedProduct} from './graphql/mutations'
import {getSavedProduct, savedProductsByUser} from './graphql/queries'
import {generateClient} from '@aws-amplify/api'

import {productsService} from './products'

export class SaveProductService {
  private static instance: SaveProductService

  public static getInstance(): SaveProductService {
    if (!SaveProductService.instance) {
      SaveProductService.instance = new SaveProductService()
    }

    return SaveProductService.instance
  }

  async isProductSaved({
    userId,
    productId,
  }: {
    userId: string
    productId: string
  }) {
    const client = generateClient()
    const graphql = await client.graphql({
      query: getSavedProduct,
      variables: {
        user_id: userId,
        product_id: productId,
      },
    })

    return graphql.data.getSavedProduct ? true : false
  }
  async saveProduct({userId, productId}: {userId: string; productId: string}) {
    const client = generateClient()
    const graphql = await client.graphql({
      query: createSavedProduct,
      variables: {
        input: {
          user_id: userId,
          product_id: productId,
        },
      },
    })

    return graphql.data.createSavedProduct
  }

  async toggleSavedProduct({
    userId,
    productId,
    isSaved,
  }: {
    userId: string
    productId: string
    isSaved: boolean
  }) {
    const client = generateClient()
    const graphql = await client.graphql({
      query: isSaved ? deleteSavedProduct : createSavedProduct,
      variables: {
        input: {
          user_id: userId,
          product_id: productId,
        },
      },
    })
    let result
    if ('deleteSavedProduct' in graphql.data) {
      const deleteResult = graphql.data as DeleteSavedProductMutation
      result = deleteResult.deleteSavedProduct as SavedProduct
    } else if ('createSavedProduct' in graphql.data) {
      const createResult = graphql.data as CreateSavedProductMutation
      result = createResult.createSavedProduct as SavedProduct
    }
    return result as SavedProduct
  }

  async getUserSavedProducts(
    {
      userId,
      nextToken,
      limit,
    }: {
      userId: string
      nextToken?: string | null
      limit?: number | null
    },
    signal?: AbortSignal,
  ) {
    const client = generateClient()
    const graphql = await client.graphql({
      query: savedProductsByUser,
      variables: {
        user_id: userId,
        sortDirection: ModelSortDirection.DESC,
        nextToken,
        limit: limit ?? 10,
      },
    })

    const savedProductsConnection = graphql.data.savedProductsByUser
    const savedProductsIds = savedProductsConnection.items.map(
      (item) => item.product_id,
    )

    if (savedProductsIds.length === 0) {
      return {
        products: [],
        nextToken: null,
      }
    }

    const products = await productsService.productsByIds(
      savedProductsIds,
      signal,
    )

    return {
      products,
      nextToken: savedProductsConnection.nextToken,
    }
  }
}

export const saveProductService = SaveProductService.getInstance()
