import Axios, {AxiosInstance} from 'axios'
import qs from 'qs'

import {applyRequestInterceptor} from './interceptors'
import {tokenInterceptor} from './token-interceptor'
import {userIdInterceptor} from './user-id-interceptor'

let browserAxiosInstance: AxiosInstance

export function makeAxiosInstance() {
  const axios = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    paramsSerializer: (params, opts) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        allowDots: opts?.dots,
      })
    },
  })

  applyRequestInterceptor(axios, [tokenInterceptor, userIdInterceptor])

  return axios
}

export function getAxiosInstance() {
  if (typeof window === 'undefined') {
    return makeAxiosInstance()
  }

  if (!browserAxiosInstance) {
    browserAxiosInstance = makeAxiosInstance()
  }
  return browserAxiosInstance
}

export default getAxiosInstance
