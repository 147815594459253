/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../types/API'

type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType
  __generatedMutationOutput: OutputType
}

export const createProductFinderRequest =
  /* GraphQL */ `mutation CreateProductFinderRequest(
  $input: CreateProductFinderRequestInput!
  $condition: ModelProductFinderRequestConditionInput
) {
  createProductFinderRequest(input: $input, condition: $condition) {
    id
    user_id
    status
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateProductFinderRequestMutationVariables,
    APITypes.CreateProductFinderRequestMutation
  >
export const updateProductFinderRequest =
  /* GraphQL */ `mutation UpdateProductFinderRequest(
  $input: UpdateProductFinderRequestInput!
  $condition: ModelProductFinderRequestConditionInput
) {
  updateProductFinderRequest(input: $input, condition: $condition) {
    id
    user_id
    status
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateProductFinderRequestMutationVariables,
    APITypes.UpdateProductFinderRequestMutation
  >
export const deleteProductFinderRequest =
  /* GraphQL */ `mutation DeleteProductFinderRequest(
  $input: DeleteProductFinderRequestInput!
  $condition: ModelProductFinderRequestConditionInput
) {
  deleteProductFinderRequest(input: $input, condition: $condition) {
    id
    user_id
    status
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteProductFinderRequestMutationVariables,
    APITypes.DeleteProductFinderRequestMutation
  >
export const createProductIdForUrl =
  /* GraphQL */ `mutation CreateProductIdForUrl(
  $input: CreateProductIdForUrlInput!
  $condition: ModelProductIdForUrlConditionInput
) {
  createProductIdForUrl(input: $input, condition: $condition) {
    url
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateProductIdForUrlMutationVariables,
    APITypes.CreateProductIdForUrlMutation
  >
export const updateProductIdForUrl =
  /* GraphQL */ `mutation UpdateProductIdForUrl(
  $input: UpdateProductIdForUrlInput!
  $condition: ModelProductIdForUrlConditionInput
) {
  updateProductIdForUrl(input: $input, condition: $condition) {
    url
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateProductIdForUrlMutationVariables,
    APITypes.UpdateProductIdForUrlMutation
  >
export const deleteProductIdForUrl =
  /* GraphQL */ `mutation DeleteProductIdForUrl(
  $input: DeleteProductIdForUrlInput!
  $condition: ModelProductIdForUrlConditionInput
) {
  deleteProductIdForUrl(input: $input, condition: $condition) {
    url
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteProductIdForUrlMutationVariables,
    APITypes.DeleteProductIdForUrlMutation
  >
export const createSavedProduct = /* GraphQL */ `mutation CreateSavedProduct(
  $input: CreateSavedProductInput!
  $condition: ModelSavedProductConditionInput
) {
  createSavedProduct(input: $input, condition: $condition) {
    user_id
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSavedProductMutationVariables,
  APITypes.CreateSavedProductMutation
>
export const updateSavedProduct = /* GraphQL */ `mutation UpdateSavedProduct(
  $input: UpdateSavedProductInput!
  $condition: ModelSavedProductConditionInput
) {
  updateSavedProduct(input: $input, condition: $condition) {
    user_id
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSavedProductMutationVariables,
  APITypes.UpdateSavedProductMutation
>
export const deleteSavedProduct = /* GraphQL */ `mutation DeleteSavedProduct(
  $input: DeleteSavedProductInput!
  $condition: ModelSavedProductConditionInput
) {
  deleteSavedProduct(input: $input, condition: $condition) {
    user_id
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSavedProductMutationVariables,
  APITypes.DeleteSavedProductMutation
>
export const createVisionSearchEntry =
  /* GraphQL */ `mutation CreateVisionSearchEntry(
  $input: CreateVisionSearchEntryInput!
  $condition: ModelVisionSearchEntryConditionInput
) {
  createVisionSearchEntry(input: $input, condition: $condition) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateVisionSearchEntryMutationVariables,
    APITypes.CreateVisionSearchEntryMutation
  >
export const updateVisionSearchEntry =
  /* GraphQL */ `mutation UpdateVisionSearchEntry(
  $input: UpdateVisionSearchEntryInput!
  $condition: ModelVisionSearchEntryConditionInput
) {
  updateVisionSearchEntry(input: $input, condition: $condition) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateVisionSearchEntryMutationVariables,
    APITypes.UpdateVisionSearchEntryMutation
  >
export const deleteVisionSearchEntry =
  /* GraphQL */ `mutation DeleteVisionSearchEntry(
  $input: DeleteVisionSearchEntryInput!
  $condition: ModelVisionSearchEntryConditionInput
) {
  deleteVisionSearchEntry(input: $input, condition: $condition) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteVisionSearchEntryMutationVariables,
    APITypes.DeleteVisionSearchEntryMutation
  >
export const createStylistRoom = /* GraphQL */ `mutation CreateStylistRoom(
  $input: CreateStylistRoomInput!
  $condition: ModelStylistRoomConditionInput
) {
  createStylistRoom(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    users {
      nextToken
      __typename
    }
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStylistRoomMutationVariables,
  APITypes.CreateStylistRoomMutation
>
export const updateStylistRoom = /* GraphQL */ `mutation UpdateStylistRoom(
  $input: UpdateStylistRoomInput!
  $condition: ModelStylistRoomConditionInput
) {
  updateStylistRoom(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    users {
      nextToken
      __typename
    }
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStylistRoomMutationVariables,
  APITypes.UpdateStylistRoomMutation
>
export const deleteStylistRoom = /* GraphQL */ `mutation DeleteStylistRoom(
  $input: DeleteStylistRoomInput!
  $condition: ModelStylistRoomConditionInput
) {
  deleteStylistRoom(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    users {
      nextToken
      __typename
    }
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStylistRoomMutationVariables,
  APITypes.DeleteStylistRoomMutation
>
export const createUserStylistRoom =
  /* GraphQL */ `mutation CreateUserStylistRoom(
  $input: CreateUserStylistRoomInput!
  $condition: ModelUserStylistRoomConditionInput
) {
  createUserStylistRoom(input: $input, condition: $condition) {
    id
    userId
    stylistRoomId
    joinedAt
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserStylistRoomMutationVariables,
    APITypes.CreateUserStylistRoomMutation
  >
export const updateUserStylistRoom =
  /* GraphQL */ `mutation UpdateUserStylistRoom(
  $input: UpdateUserStylistRoomInput!
  $condition: ModelUserStylistRoomConditionInput
) {
  updateUserStylistRoom(input: $input, condition: $condition) {
    id
    userId
    stylistRoomId
    joinedAt
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserStylistRoomMutationVariables,
    APITypes.UpdateUserStylistRoomMutation
  >
export const deleteUserStylistRoom =
  /* GraphQL */ `mutation DeleteUserStylistRoom(
  $input: DeleteUserStylistRoomInput!
  $condition: ModelUserStylistRoomConditionInput
) {
  deleteUserStylistRoom(input: $input, condition: $condition) {
    id
    userId
    stylistRoomId
    joinedAt
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteUserStylistRoomMutationVariables,
    APITypes.DeleteUserStylistRoomMutation
  >
export const createStylistRoomEntry =
  /* GraphQL */ `mutation CreateStylistRoomEntry(
  $input: CreateStylistRoomEntryInput!
  $condition: ModelStylistRoomEntryConditionInput
) {
  createStylistRoomEntry(input: $input, condition: $condition) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateStylistRoomEntryMutationVariables,
    APITypes.CreateStylistRoomEntryMutation
  >
export const updateStylistRoomEntry =
  /* GraphQL */ `mutation UpdateStylistRoomEntry(
  $input: UpdateStylistRoomEntryInput!
  $condition: ModelStylistRoomEntryConditionInput
) {
  updateStylistRoomEntry(input: $input, condition: $condition) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateStylistRoomEntryMutationVariables,
    APITypes.UpdateStylistRoomEntryMutation
  >
export const deleteStylistRoomEntry =
  /* GraphQL */ `mutation DeleteStylistRoomEntry(
  $input: DeleteStylistRoomEntryInput!
  $condition: ModelStylistRoomEntryConditionInput
) {
  deleteStylistRoomEntry(input: $input, condition: $condition) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteStylistRoomEntryMutationVariables,
    APITypes.DeleteStylistRoomEntryMutation
  >
