import {ModelSortDirection, VisionSearchEntry} from './types'
// Update with actual API types
import {createVisionSearchEntry} from './graphql/mutations'
import {
  getVisionSearchEntry,
  visionSearchEntriesByUserId,
} from './graphql/queries'
import {generateClient} from '@aws-amplify/api'

import {VisionAnalysisResult} from './vision'

export class VisionSearchEntries {
  private static instance: VisionSearchEntries

  public static getInstance(): VisionSearchEntries {
    if (!VisionSearchEntries.instance) {
      VisionSearchEntries.instance = new VisionSearchEntries()
    }

    return VisionSearchEntries.instance
  }

  // Query a specific entry by its ID
  async getEntryById(id: string) {
    const client = generateClient()
    const graphql = await client.graphql({
      query: getVisionSearchEntry, // Query to get the entry by ID
      variables: {
        id,
      },
    })

    return graphql.data.getVisionSearchEntry as VisionSearchEntry | null
  }

  // Create a new VisionSearch entry
  async createEntry({
    userId,
    imageUrl,
    searchResult,
  }: {
    userId: string
    imageUrl: string
    searchResult: VisionAnalysisResult // You can adjust this based on the actual structure
  }) {
    const client = generateClient()
    const graphql = await client.graphql({
      query: createVisionSearchEntry, // Mutation to create an entry
      variables: {
        input: {
          user_id: userId,
          image_url: imageUrl,
          result: JSON.stringify(searchResult),
        },
      },
    })

    return graphql.data.createVisionSearchEntry as VisionSearchEntry
  }

  // Get all entries for a specific user, with optional pagination
  async getUserVisionSearchEntries(
    {
      userId,
      nextToken,
      limit,
    }: {
      userId: string
      nextToken?: string | null
      limit?: number | null
    },
    signal?: AbortSignal,
  ) {
    const client = generateClient()
    const graphql = await client.graphql({
      query: visionSearchEntriesByUserId, // Query to get all entries for a user
      variables: {
        user_id: userId,
        sortDirection: ModelSortDirection.DESC, // Sort by most recent first
        nextToken,
        limit: limit ?? 10, // Default limit of 10
      },
    })

    const visionSearchEntriesConnection =
      graphql.data.visionSearchEntriesByUserId
    const entries = visionSearchEntriesConnection.items as VisionSearchEntry[]

    return {
      entries,
      nextToken: visionSearchEntriesConnection.nextToken,
    }
  }
}

// Export the singleton instance of VisionSearchEntries
export const visionSearchEntriesService = VisionSearchEntries.getInstance()
