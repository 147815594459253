import {uploadData} from 'aws-amplify/storage'
import {useCallback, useState} from 'react'
import {v4 as uuid} from 'uuid'

import {imageURLFromCDN} from '@atorie/core/utils'

import {
  ProductFinderResponse,
  productFinderService,
} from '@atorie/api/product-finder'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import {useAuthUser} from '../use-auth-user'
import {productFinderByIdQueryOptions} from './use-product-finder-by-id.query'
import {
  productFinderUserHistoryInfiniteQueryOption,
  productFinderUserHistoryQueryOption,
} from './use-product-finder-user-history.query'

export interface ProductFinderInput {
  imageUrl: string | File
  userId: string
  requestId: string
}

export interface ProductFinderMutationOptions
  extends Omit<
    UseMutationOptions<ProductFinderResponse, Error, ProductFinderInput>,
    'mutationFn'
  > {}

export function useProductFinderMutation({
  onSuccess,
  ...opts
}: ProductFinderMutationOptions = {}) {
  const [progress, setProgress] = useState<Record<string, number>>({})
  const queryClient = useQueryClient()
  const {user} = useAuthUser()

  const onProgress = useCallback(
    ({id, progress}: {id: string; progress: number}) => {
      setProgress((prev) => ({...prev, [id]: progress}))
    },
    [],
  )

  const findProductMutation = useMutation({
    async mutationFn({imageUrl, userId, requestId}: ProductFinderInput) {
      if (typeof imageUrl === 'string') {
        return productFinderService.findProduct({
          imageUrl,
          userId,
          requestId,
        })
      }

      const result = await uploadData({
        path: `public/images/${uuid()}`,
        data: imageUrl,
        options: {
          onProgress: ({transferredBytes, totalBytes}) => {
            if (totalBytes) {
              onProgress({
                id: requestId,
                progress: (transferredBytes / totalBytes) * 100,
              })
            }
          },
        },
      }).result.catch((err) => {
        console.error('aws3', err)
        throw err
      })

      return productFinderService.findProduct({
        imageUrl: imageURLFromCDN(result.path),
        userId,
        requestId,
      })
    },
    onSuccess(...args) {
      onProgress({id: args[0].request_id, progress: 0})
      if (user?.id) {
        queryClient.invalidateQueries(
          productFinderUserHistoryQueryOption({userId: user?.id}),
        )

        queryClient.invalidateQueries(
          productFinderUserHistoryInfiniteQueryOption({userId: user?.id}),
        )
      }
      queryClient.invalidateQueries(
        productFinderByIdQueryOptions(args[0].request_id),
      )
      onSuccess?.(...args)
    },
    ...opts,
  })

  return {
    ...findProductMutation,
    progress,
  }
}
