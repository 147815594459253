import {ProductsQueryVariables} from '../types/storefront.generated'
import {getStorefrontApiClient} from '@atorie/core/shopify'

import ProductCardFragment from '../admin/graphql/product-card.fragment'

export function productsQuery(variables: ProductsQueryVariables) {
  const shopifyClient = getStorefrontApiClient()

  return shopifyClient.request(
    `#graphql
    query Products($first: Int, $after: String, $before: String, $last: Int, $query: String, $sortKey: ProductSortKeys, $reverse: Boolean) {
      products(first: $first, after: $after, before: $before, last:  $last, query: $query, sortKey: $sortKey, reverse: $reverse) {
        nodes {
          ...ProductCard
        }
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
        }
      }
    }
    ${ProductCardFragment}
    `,
    {
      variables,
    },
  )
}
