import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAuthUser} from '../use-auth-user'
import {productFinderUserHistoryQueryOption} from './use-product-finder-user-history.query'
import {useProductFinderMutation} from './use-product-finder.mutation'

export function useRetryProductFinderRequestMutation() {
  const queryClient = useQueryClient()
  const {user} = useAuthUser()
  const productFinderMutation = useProductFinderMutation()

  return useMutation({
    async mutationFn({id}: {id: string}) {
      if (!user) {
        throw new Error('User is not authenticated')
      }

      const historyProductFinder = queryClient.getQueryData(
        productFinderUserHistoryQueryOption({userId: user.id}).queryKey,
      )

      if (!historyProductFinder) {
        throw new Error('No product history found')
      }

      const requestIdx = historyProductFinder.findIndex((product) => {
        return product.id === id
      })

      if (requestIdx === -1) {
        throw new Error('No product found')
      }

      const productFinder = historyProductFinder[requestIdx]!
      const productStatus = productFinder.status.toLowerCase()

      if (productStatus === 'processing') {
        throw new Error('Product already processing')
      }

      if (productStatus === 'completed') {
        throw new Error('Product already completed')
      }

      productFinder.status = 'processing'

      queryClient.setQueryData(
        productFinderUserHistoryQueryOption({userId: user.id}).queryKey,
        historyProductFinder,
      )

      return productFinderMutation.mutateAsync({
        userId: user.id,
        requestId: id,
        imageUrl: productFinder.image_url,
      })
    },
    onSuccess() {},
  })
}
