import {AxiosInstance, axios} from '@atorie/core/axios'

import {ModelSortDirection, ProductFinderRequest} from './types/API'
import {productFinderRequestsByUserId} from './graphql/queries'
import {generateClient} from '@aws-amplify/api'

import {Product} from './products'

export interface ProductFinderData {
  brand_name: string
  product_name: string
  link: string[]
  preferred_links: string[]
  price: string[]
  source: string[]
  source_icon: string[]
  thumbnail: string[]
  title: string[]
  product_id: string[]
  scrape_images: string[]
  blurhash: string[]
  finder_result_count: number
}

export const convertFinderDataToProduct = (finderData: ProductFinderData) => {
  return {
    id: finderData.product_id[0] || '', // Use the first product_id as the id, fallback to an empty string
    name: finderData.product_name,
    brand_name: finderData.brand_name,
    price: parseFloat(finderData.price[0] || '0') || null, // Convert the first price to number, default to null
    finder_data: finderData,
    urls: finderData.link,
  } as Product
}

export interface ProductFinderResponse {
  message: string
  request_id: string
}

type ProductFinderRequestConnection = {
  __typename: 'ModelProductFinderRequestConnection'
  items: ProductFinderRequest[]
  nextToken?: string | null
}

export class ProductFinderService {
  private axios: AxiosInstance
  constructor() {
    this.axios = axios.create({
      baseURL: process.env.NEXT_PUBLIC_PRODUCT_FINDER_API_URL,
    })
  }
  async findProduct({
    imageUrl,
    userId,
    requestId,
  }: {
    imageUrl: string
    userId: string
    requestId: string
  }) {
    const response = await this.axios.get<ProductFinderResponse>(
      '', // We only need to use the base URL since we are using the full URL in the axios instance
      {
        params: {
          image_url: imageUrl,
          user_id: userId,
          request_id: requestId,
        },
      },
    )
    return response.data
  }

  async finderProductUserHistory({
    userId,
    limit = 30,
    nextToken,
  }: {
    userId: string
    limit?: number
    nextToken?: string
  }): Promise<ProductFinderRequestConnection> {
    const client = generateClient()
    const graphql = await client.graphql({
      query: productFinderRequestsByUserId,
      variables: {
        user_id: userId,
        sortDirection: ModelSortDirection.DESC,
        limit: 30,
        nextToken: nextToken ?? null,
      },
    })

    if (graphql.errors) {
      throw new Error(graphql.errors[0]?.message ?? 'Unknown error')
    }

    return graphql.data.productFinderRequestsByUserId as ProductFinderRequestConnection
  }
}

export const productFinderService = new ProductFinderService()
