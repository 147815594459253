import {generateClient} from 'aws-amplify/api'
import {useEffect, useMemo} from 'react'

import {onUpdateProductFinderRequest} from '@atorie/api/graphql'
import {productFinderService} from '@atorie/api/product-finder'
import {
  infiniteQueryOptions,
  queryOptions,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {useAuthUser} from '../use-auth-user'
import {ProductFinderResultObject} from './product-finder.types'
import {productFinderByIdQueryOptions} from './use-product-finder-by-id.query'

export function productFinderUserHistoryQueryOption({
  userId,
}: {
  userId?: string
}) {
  return queryOptions({
    queryKey: ['product-finder', userId] as const,
    async queryFn() {
      if (!userId) return []

      const productFinder = await productFinderService.finderProductUserHistory(
        {userId},
      )
      return productFinder.items
    },
    select: (data) => {
      return data.map(({result, ...item}) => {
        if (!item) return null
        const newItem: typeof item & {
          result: ProductFinderResultObject | null
        } = {...item, result: null}

        try {
          newItem.result = JSON.parse(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            result ?? 'null',
          )
        } catch (e) {
          newItem.result = null
        }

        return newItem
      })
    },
  })
}

interface ProductFinderUserHistoryInfiniteQueryOption {
  userId?: string
}

export function productFinderUserHistoryInfiniteQueryOption({
  userId,
}: ProductFinderUserHistoryInfiniteQueryOption) {
  return infiniteQueryOptions({
    queryKey: ['user', userId, 'finder-history', 'products'] as const,
    queryFn: async ({pageParam}) => {
      if (!userId) {
        return Promise.resolve({
          items: [],
          nextToken: undefined,
        })
      }
      const productFinder = await productFinderService.finderProductUserHistory(
        {userId, limit: 30, nextToken: pageParam.nextToken},
      )

      return {
        items: productFinder.items.map(({result, ...item}) => {
          const newItem: typeof item & {
            result: ProductFinderResultObject | null
          } = {...item, result: null}
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            newItem.result = JSON.parse(result ?? 'null')
          } catch (e) {
            newItem.result = null
          }
          return newItem
        }),

        nextToken: productFinder.nextToken,
      }
    },
    getNextPageParam(lastPage) {
      if (!lastPage.nextToken) {
        return
      }

      return {
        nextToken: lastPage.nextToken,
      }
    },
    select(data) {
      return data
    },
    initialData: undefined,
    initialPageParam: {nextToken: undefined} as {nextToken?: string},
  })
}

export function useProductFinderHistoryInfiniteQuery() {
  const queryClient = useQueryClient()
  const graphqlClient = useMemo(() => generateClient(), [])
  const {user} = useAuthUser()

  useEffect(() => {
    if (!user?.id) return
    const onUpdateProductFinder = graphqlClient.graphql({
      query: onUpdateProductFinderRequest,
      variables: {
        filter: {
          // use user id from auth
          user_id: {eq: user.id},
        },
      },
    })

    const subscription = onUpdateProductFinder.subscribe({
      next(value) {
        const id = value.data.onUpdateProductFinderRequest.id

        queryClient.invalidateQueries(
          productFinderUserHistoryInfiniteQueryOption({userId: user.id}),
        )
        queryClient.invalidateQueries(productFinderByIdQueryOptions(id))
      },
    })

    return () => subscription.unsubscribe()
  }, [graphqlClient, queryClient, user?.id])

  return useInfiniteQuery(
    productFinderUserHistoryInfiniteQueryOption({userId: user?.id}),
  )
}

export function useProductFinderHistoryQuery() {
  const queryClient = useQueryClient()
  const graphqlClient = useMemo(() => generateClient(), [])
  const {user} = useAuthUser()

  useEffect(() => {
    if (!user?.id) return
    const onUpdateProductFinder = graphqlClient.graphql({
      query: onUpdateProductFinderRequest,
      variables: {
        filter: {
          // use user id from auth
          user_id: {eq: user.id},
        },
      },
    })

    const subscription = onUpdateProductFinder.subscribe({
      next(value) {
        const id = value.data.onUpdateProductFinderRequest.id

        queryClient.invalidateQueries(
          productFinderUserHistoryQueryOption({userId: user.id}),
        )
        queryClient.invalidateQueries(productFinderByIdQueryOptions(id))
      },
    })

    return () => subscription.unsubscribe()
  }, [graphqlClient, queryClient, user?.id])

  return useQuery(productFinderUserHistoryQueryOption({userId: user?.id}))
}
