import getAxiosInstance, {AxiosInstance} from '@atorie/core/axios'

export class AnalysisService {
  private static instance: AnalysisService
  private axios: AxiosInstance

  public static getInstance(): AnalysisService {
    if (!AnalysisService.instance) {
      AnalysisService.instance = new AnalysisService()
    }
    return AnalysisService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async analyzeProduct(productId: string): Promise<any> {
    try {
      const response = await this.axios.post('/shopify/analysis', {
        product_id: productId,
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data
    } catch (error) {
      console.error('Error analyzing product:', error)
      throw error
    }
  }

  async getProductAnalysis(productId: string): Promise<any> {
    console.log('Getting product analysis for', productId)
    try {
      const response = await this.axios.get(`/shopify/analysis/${productId}`)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data.analysis
    } catch (error) {
      console.error('Error getting product analysis:', error)
      throw error
    }
  }
}

export const analysisService = AnalysisService.getInstance()
