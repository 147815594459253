export const ProductCardFragment = `#graphql
  fragment ProductCard on Product {
    id
    title
    publishedAt
    handle
    vendor
    availableForSale
    productType
    images(first: 50) {
      edges {
        node {
          id
          src
          url
          __typename
        }
      }
    }
    variants(first: 50) {
      nodes {
        id
        image {
          url
          altText
          width
          height
        }
        price {
          amount
          currencyCode
        }
        compareAtPrice {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
        product {
          handle
          title
        }
      }
    }

  }
`

export default ProductCardFragment
