/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../types/API'

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType
  __generatedQueryOutput: OutputType
}

export const getProductFinderRequest =
  /* GraphQL */ `query GetProductFinderRequest($id: ID!) {
  getProductFinderRequest(id: $id) {
    id
    user_id
    status
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetProductFinderRequestQueryVariables,
    APITypes.GetProductFinderRequestQuery
  >
export const listProductFinderRequests =
  /* GraphQL */ `query ListProductFinderRequests(
  $id: ID
  $filter: ModelProductFinderRequestFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listProductFinderRequests(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user_id
      status
      image_url
      result
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListProductFinderRequestsQueryVariables,
    APITypes.ListProductFinderRequestsQuery
  >
export const getProductIdForUrl =
  /* GraphQL */ `query GetProductIdForUrl($url: ID!) {
  getProductIdForUrl(url: $url) {
    url
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetProductIdForUrlQueryVariables,
    APITypes.GetProductIdForUrlQuery
  >
export const listProductIdForUrls = /* GraphQL */ `query ListProductIdForUrls(
  $url: ID
  $filter: ModelProductIdForUrlFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listProductIdForUrls(
    url: $url
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      url
      product_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductIdForUrlsQueryVariables,
  APITypes.ListProductIdForUrlsQuery
>
export const getSavedProduct =
  /* GraphQL */ `query GetSavedProduct($user_id: ID!, $product_id: ID!) {
  getSavedProduct(user_id: $user_id, product_id: $product_id) {
    user_id
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetSavedProductQueryVariables,
    APITypes.GetSavedProductQuery
  >
export const listSavedProducts = /* GraphQL */ `query ListSavedProducts(
  $user_id: ID
  $product_id: ModelIDKeyConditionInput
  $filter: ModelSavedProductFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSavedProducts(
    user_id: $user_id
    product_id: $product_id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      user_id
      product_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSavedProductsQueryVariables,
  APITypes.ListSavedProductsQuery
>
export const getVisionSearchEntry =
  /* GraphQL */ `query GetVisionSearchEntry($id: ID!) {
  getVisionSearchEntry(id: $id) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetVisionSearchEntryQueryVariables,
    APITypes.GetVisionSearchEntryQuery
  >
export const listVisionSearchEntries =
  /* GraphQL */ `query ListVisionSearchEntries(
  $id: ID
  $filter: ModelVisionSearchEntryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVisionSearchEntries(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user_id
      image_url
      result
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListVisionSearchEntriesQueryVariables,
    APITypes.ListVisionSearchEntriesQuery
  >
export const getStylistRoom = /* GraphQL */ `query GetStylistRoom($id: ID!) {
  getStylistRoom(id: $id) {
    id
    name
    createdAt
    updatedAt
    users {
      nextToken
      __typename
    }
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStylistRoomQueryVariables,
  APITypes.GetStylistRoomQuery
>
export const listStylistRooms = /* GraphQL */ `query ListStylistRooms(
  $id: ID
  $filter: ModelStylistRoomFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStylistRooms(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      modelBuild
      userStatus
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStylistRoomsQueryVariables,
  APITypes.ListStylistRoomsQuery
>
export const getUserStylistRoom =
  /* GraphQL */ `query GetUserStylistRoom($id: ID!) {
  getUserStylistRoom(id: $id) {
    id
    userId
    stylistRoomId
    joinedAt
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserStylistRoomQueryVariables,
    APITypes.GetUserStylistRoomQuery
  >
export const listUserStylistRooms = /* GraphQL */ `query ListUserStylistRooms(
  $id: ID
  $filter: ModelUserStylistRoomFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserStylistRooms(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      userId
      stylistRoomId
      joinedAt
      role
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserStylistRoomsQueryVariables,
  APITypes.ListUserStylistRoomsQuery
>
export const getStylistRoomEntry =
  /* GraphQL */ `query GetStylistRoomEntry($id: ID!) {
  getStylistRoomEntry(id: $id) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetStylistRoomEntryQueryVariables,
    APITypes.GetStylistRoomEntryQuery
  >
export const listStylistRoomEntries =
  /* GraphQL */ `query ListStylistRoomEntries(
  $id: ID
  $filter: ModelStylistRoomEntryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStylistRoomEntries(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      stylistRoomId
      senderId
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListStylistRoomEntriesQueryVariables,
    APITypes.ListStylistRoomEntriesQuery
  >
export const productFinderRequestsByUserId =
  /* GraphQL */ `query ProductFinderRequestsByUserId(
  $user_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFinderRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  productFinderRequestsByUserId(
    user_id: $user_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      status
      image_url
      result
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ProductFinderRequestsByUserIdQueryVariables,
    APITypes.ProductFinderRequestsByUserIdQuery
  >
export const urlsByProductId = /* GraphQL */ `query UrlsByProductId(
  $product_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProductIdForUrlFilterInput
  $limit: Int
  $nextToken: String
) {
  urlsByProductId(
    product_id: $product_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      url
      product_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UrlsByProductIdQueryVariables,
  APITypes.UrlsByProductIdQuery
>
export const savedProductsByUser = /* GraphQL */ `query SavedProductsByUser(
  $user_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSavedProductFilterInput
  $limit: Int
  $nextToken: String
) {
  savedProductsByUser(
    user_id: $user_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      user_id
      product_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SavedProductsByUserQueryVariables,
  APITypes.SavedProductsByUserQuery
>
export const savesForProduct = /* GraphQL */ `query SavesForProduct(
  $product_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSavedProductFilterInput
  $limit: Int
  $nextToken: String
) {
  savesForProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      user_id
      product_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SavesForProductQueryVariables,
  APITypes.SavesForProductQuery
>
export const visionSearchEntriesByUserId =
  /* GraphQL */ `query VisionSearchEntriesByUserId(
  $user_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelVisionSearchEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  visionSearchEntriesByUserId(
    user_id: $user_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      image_url
      result
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.VisionSearchEntriesByUserIdQueryVariables,
    APITypes.VisionSearchEntriesByUserIdQuery
  >
export const userStylistRoomsByUserIdAndStylistRoomId =
  /* GraphQL */ `query UserStylistRoomsByUserIdAndStylistRoomId(
  $userId: ID!
  $stylistRoomId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserStylistRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  userStylistRoomsByUserIdAndStylistRoomId(
    userId: $userId
    stylistRoomId: $stylistRoomId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      stylistRoomId
      joinedAt
      role
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.UserStylistRoomsByUserIdAndStylistRoomIdQueryVariables,
    APITypes.UserStylistRoomsByUserIdAndStylistRoomIdQuery
  >
export const userStylistRoomsByStylistRoomIdAndUserId =
  /* GraphQL */ `query UserStylistRoomsByStylistRoomIdAndUserId(
  $stylistRoomId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserStylistRoomFilterInput
  $limit: Int
  $nextToken: String
) {
  userStylistRoomsByStylistRoomIdAndUserId(
    stylistRoomId: $stylistRoomId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      stylistRoomId
      joinedAt
      role
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.UserStylistRoomsByStylistRoomIdAndUserIdQueryVariables,
    APITypes.UserStylistRoomsByStylistRoomIdAndUserIdQuery
  >
export const stylistRoomEntriesByStylistRoomId =
  /* GraphQL */ `query StylistRoomEntriesByStylistRoomId(
  $stylistRoomId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStylistRoomEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  stylistRoomEntriesByStylistRoomId(
    stylistRoomId: $stylistRoomId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stylistRoomId
      senderId
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.StylistRoomEntriesByStylistRoomIdQueryVariables,
    APITypes.StylistRoomEntriesByStylistRoomIdQuery
  >
