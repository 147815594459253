import {saveProductService} from '@atorie/api/save-products'
import {SavedProduct} from '@atorie/api/types'
import {
  infiniteQueryOptions,
  queryOptions,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {useAuthUser} from './use-auth-user'

interface SaveProductMutationInput {
  productId: string
  isSaved: boolean
}
export function useIsProductSavedQuery(productId?: string) {
  const {user, isLoading} = useAuthUser()

  return useQuery({
    ...isSavedProductQueryOptions(user?.id ?? '', productId!),
    enabled: !!user?.id && !isLoading && !!productId,
  })
}

interface SaveProductMutationOptions
  extends Omit<
    UseMutationOptions<SavedProduct, Error, SaveProductMutationInput>,
    'mutationFn'
  > {}

export function useToggleSavedProductMutation({
  onError,
  onSuccess,
  onSettled,
  ...opts
}: SaveProductMutationOptions = {}) {
  const queryClient = useQueryClient()
  const {user} = useAuthUser()

  const toggleSavedProductMutation = useMutation({
    async mutationFn({productId, isSaved}: SaveProductMutationInput) {
      if (!user) {
        throw new Error('User is not authenticated')
      }

      queryClient.setQueryData(
        isSavedProductQueryOptions(user.id, productId).queryKey,
        !isSaved,
      )

      // Add the product to the user's saved products list to avoid waiting for the query to refetch
      queryClient.setQueryData(
        userProductsSavedInfiniteQueryOptions(user.id).queryKey,
        (oldData) => {
          if (!oldData) {
            return
          }

          if (isSaved) {
            return oldData
          }
        },
      )

      return await saveProductService.toggleSavedProduct({
        userId: user.id,
        productId,
        isSaved,
      })
    },
    onSuccess(data, variables, ctx) {
      const {user_id, product_id} = data

      queryClient.invalidateQueries(
        isSavedProductQueryOptions(user_id, product_id),
      )

      onSuccess?.(data, variables, ctx)
    },
    onError(error, variables, ctx) {
      const {productId, isSaved} = variables
      if (!user) {
        return onError?.(error, variables, ctx)
      }

      queryClient.setQueryData(
        isSavedProductQueryOptions(user.id, productId).queryKey,
        isSaved,
      )

      onError?.(error, variables, ctx)
    },
    onSettled(data, error, variables, ctx) {
      if (!user) {
        return onSettled?.(data, error, variables, ctx)
      }

      queryClient.invalidateQueries(
        userProductsSavedInfiniteQueryOptions(user.id),
      )
      onSettled?.(data, error, variables, ctx)
    },
    ...opts,
  })

  return {
    ...toggleSavedProductMutation,
  }
}

export function isSavedProductQueryOptions(userId: string, productId: string) {
  return queryOptions({
    queryKey: ['products', productId, 'is-saved', userId] as const,
    queryFn: async ({queryKey: [_, productId, __, userId]}) => {
      const result = await saveProductService.isProductSaved({
        userId,
        productId,
      })

      return result
    },
  })
}

export function userProductsSavedInfiniteQueryOptions(userId: string) {
  return infiniteQueryOptions({
    queryKey: ['products', 'user', 'saved', userId] as const,
    queryFn: async ({queryKey: [_, __, ___, id], pageParam, signal}) => {
      const result = await saveProductService.getUserSavedProducts(
        {
          userId: id,
          nextToken: pageParam,
          limit: 20,
        },
        signal,
      )

      return {
        products: result.products.filter(Boolean),
        nextToken: result.nextToken,
      }
    },
    getNextPageParam: (lastPage) => {
      const hasNext = lastPage.nextToken !== null
      if (!hasNext) return

      return lastPage.nextToken
    },
    select: (data) => {
      return {
        pageParams: data.pageParams,
        pages: data.pages.map((page) => page.products),
      }
    },
    initialData: undefined,
    initialPageParam: undefined as string | undefined,
  })
}

export function useUserProductSaved() {
  const {user} = useAuthUser()
  return useInfiniteQuery({
    ...userProductsSavedInfiniteQueryOptions(user?.id ?? ''),
    enabled: !!user?.id,
  })
}
