import {useCallback, useState} from 'react'
import {v4 as uuid} from 'uuid'

import {ProductFinderResponse} from '@atorie/api/product-finder'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'

import {useAuthUser} from '../use-auth-user'
import {useProductFinderMutation} from './use-product-finder.mutation'

interface MultiProductURLFinderMutationOptions
  extends Omit<
    UseMutationOptions<
      PromiseSettledResult<ProductFinderResponse>[],
      Error,
      {
        urls: string[]
      }
    >,
    'mutationFn'
  > {}

export function useMultiProductsURLFinderMutation({
  onSuccess,
  ...opts
}: MultiProductURLFinderMutationOptions = {}) {
  const {user} = useAuthUser()
  const productFinderMutation = useProductFinderMutation({})
  const [count, setCount] = useState(0)
  const [progress, setProgress] = useState(0)

  const updateProgress = useCallback(() => {
    setProgress((prev) => {
      return prev + 1
    })
  }, [])

  const updateCount = useCallback((count: number) => {
    setCount(count)
  }, [])

  const {
    reset: multiProductsFinderMutationReset,
    ...multiProductsFinderMutation
  } = useMutation({
    async mutationFn({urls}) {
      updateCount(urls.length)

      if (user == null) {
        throw new Error('User is not authenticated')
      }

      return Promise.allSettled(
        urls.map(async (url) => {
          const res = await productFinderMutation.mutateAsync({
            imageUrl: url,
            userId: user.id,
            requestId: uuid(),
          })

          updateProgress()
          return res
        }),
      )
    },
    onSuccess(...args) {
      onSuccess?.(...args)
    },
    ...opts,
  })

  const reset = useCallback(() => {
    setProgress(0)
    setCount(0)
    multiProductsFinderMutationReset()
  }, [multiProductsFinderMutationReset])

  return {
    ...multiProductsFinderMutation,
    reset,
    count,
    progress,
  }
}
