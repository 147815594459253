import { analysisService } from "@atorie/api/analysis";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export function productAnalysisByIdOptions(id: string) {
  return queryOptions({
    queryKey: ['product-analysis', id],
    queryFn: ({ queryKey: [, id] }) => {
      if (!id) {
        throw new Error("No product analysis id provided")
      }
      return analysisService.getProductAnalysis(id)
    },
  })
}

export function useProductAnalysisByIdQuery(id: string) {
  return useSuspenseQuery(productAnalysisByIdOptions(id));
}
