import {ProductsByIdsQuery} from '../types/storefront.generated'
import {getStorefrontApiClient} from '@atorie/core/shopify'

import ProductCardFragment from '../admin/graphql/product-card.fragment'

export function productsByIdsQuery(ids: string[]) {
  const shopifyClient = getStorefrontApiClient()

  return shopifyClient.request(
    `#graphql
    query ProductsByIds ($ids: [ID!]!) {
      nodes(ids: $ids) {
        ... on Product {
          ...ProductCard
        }
      }
    }
    ${ProductCardFragment}
    `,
    {
      variables: {ids},
    },
  )
}
