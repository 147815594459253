import {uploadData} from 'aws-amplify/storage'
import {useCallback, useState} from 'react'
import {v4 as uuid} from 'uuid'

import {imageURLFromCDN} from '@atorie/core/utils'
import {VisionAnalysisResult, visionService} from '@atorie/api/vision'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'

export interface VisionProductSearchInput {
  imageUrl: string | File
}

export interface VisionProductSearchResult {
  result: VisionAnalysisResult
  url: string
}

export interface VisionProductSearchMutationOptions
  extends Omit<
    UseMutationOptions<
      VisionProductSearchResult,
      Error,
      VisionProductSearchInput
    >,
    'mutationFn'
  > {}

export function useVisionProductSearchMutation({
  ...opts
}: VisionProductSearchMutationOptions = {}) {
  const [progress, setProgress] = useState<Record<string, number>>({})

  const onProgress = useCallback(({progress}: {progress: number}) => {
    setProgress((prev) => ({...prev}))
  }, [])

  const visionProductSearchMutation = useMutation({
    async mutationFn({imageUrl}: VisionProductSearchInput) {
      if (typeof imageUrl === 'string') {
        // Directly analyze the image URL
        return {
          result: await visionService.analyzeImageUrl(imageUrl),
          url: imageUrl,
        }
      }

      // Convert the File to a byte array
      const fileArrayBuffer = await imageUrl.arrayBuffer()
      const byteArray = new Uint8Array(fileArrayBuffer)

      // Start both operations in parallel
      const [uploadResult, analysisResult] = await Promise.all([
        uploadData({
          path: `public/images/${uuid()}.jpg`,
          data: imageUrl,
          options: {
            onProgress: ({transferredBytes, totalBytes}) => {
              if (totalBytes) {
                onProgress({
                  progress: (transferredBytes / totalBytes) * 100,
                })
              }
            },
          },
        }).result,
        visionService.analyzeImage(byteArray),
      ])

      return {
        result: analysisResult,
        url: imageURLFromCDN(uploadResult.path),
      }
    },
    ...opts,
  })

  return {
    ...visionProductSearchMutation,
    progress,
  }
}

export interface MultiVisionProductSearchMutationOptions
  extends Omit<
    UseMutationOptions<
      PromiseSettledResult<VisionProductSearchResult>[],
      Error,
      {
        files: FileList | File[]
      }
    >,
    'mutationFn'
  > {}

export function useMultiVisionProductSearchMutation({
  onSuccess,
  ...opts
}: MultiVisionProductSearchMutationOptions = {}) {
  const visionProductSearchMutation = useVisionProductSearchMutation({})
  const [count, setCount] = useState(0)
  const [progress, setProgress] = useState(0)

  const updateProgress = useCallback(() => {
    setProgress((prev) => prev + 1)
  }, [])

  const updateCount = useCallback((count: number) => {
    setCount(count)
  }, [])

  const {
    reset: multiVisionProductSearchMutationReset,
    ...multiVisionProductSearchMutation
  } = useMutation({
    async mutationFn({files}) {
      const filesArr = files instanceof FileList ? Array.from(files) : files
      updateCount(filesArr.length)

      const allFilesPromise = await Promise.allSettled(
        filesArr.map(async (file) => {
          const res = await visionProductSearchMutation.mutateAsync({
            imageUrl: file,
          })

          updateProgress()
          return res
        }),
      )

      const allHasError = allFilesPromise.every(
        (promise) => promise.status === 'rejected',
      )

      if (allHasError) {
        throw new Error('All files failed')
      }

      return allFilesPromise
    },
    onSuccess(...args) {
      onSuccess?.(...args)
    },
    ...opts,
  })

  const reset = useCallback(() => {
    setProgress(0)
    setCount(0)
    multiVisionProductSearchMutationReset()
  }, [multiVisionProductSearchMutationReset])

  return {
    ...multiVisionProductSearchMutation,
    reset,
    count,
    progress,
  }
}
