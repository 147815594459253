'use client'

import mixpanel from 'mixpanel-browser'

class MixpanelService {
  constructor(token: string) {
    mixpanel.init(token)
  }

  track(eventName: string, properties?: {[key: string]: any}) {
    mixpanel.track(eventName, properties)
  }

  identify<T extends {id: string}>(user: T) {
    mixpanel.identify(user.id)
  }

  setUserProperties(properties: {[key: string]: any}) {
    mixpanel.people.set(properties)
  }
}

const mixpanelService = new MixpanelService(
  process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string,
)

export default mixpanelService
