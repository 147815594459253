import {generateClient} from 'aws-amplify/api'
import {useEffect, useMemo} from 'react'

import {getProductFinderRequest, onUpdateProductFinderRequest} from '@atorie/api/graphql'
import {
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import {ProductFinderResultObject} from './product-finder.types'

export function productFinderByIdQueryOptions(id: string) {
  const gqlClient = generateClient()

  return queryOptions({
    queryKey: ['product-finder', id] as const,
    queryFn: async ({queryKey: [, id]}) => {
      const query = await gqlClient.graphql({
        query: getProductFinderRequest,
        variables: {id},
      })

      const resultParse = JSON.parse(
        query.data.getProductFinderRequest?.result ?? 'null',
      ) as ProductFinderResultObject | null

      const result = Object.entries(resultParse ?? {})

      return {
        ...query.data.getProductFinderRequest,
        result,
      }
    },
  })
}

export function useProductFinderByIdQuery(id: string) {
  const gqlClient = useMemo(() => generateClient(), [])
  const queryClient = useQueryClient()
  const productFinderQuery = useQuery(productFinderByIdQueryOptions(id))

  // STATUS: Completed, Processing, Failed
  const status = productFinderQuery.data?.status?.toLocaleLowerCase()
  const isComplete = status === 'completed'
  const isFailed = status === 'failed'

  useEffect(() => {
    if (isComplete) {
      return
    }

    const query = gqlClient.graphql({
      query: onUpdateProductFinderRequest,
      variables: {
        filter: {id: {eq: id}},
      },
    })

    const subscription = query.subscribe({
      next() {
        console.log('subscription next()')
        queryClient.invalidateQueries(productFinderByIdQueryOptions(id))
      },
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [id, gqlClient, queryClient, isComplete, isFailed, status])

  return {
    ...productFinderQuery,
    isComplete,
    isFailed,
  }
}

export function useProductFinderByIdSuspenseQuery(id: string) {
  return useSuspenseQuery(productFinderByIdQueryOptions(id))
}
