import {v4 as uuid} from 'uuid'

import {getAxiosInstance} from '@atorie/core/axios'

export interface User {
  id: string
  username: string
  email: string
  password: null
  role: string
  image_key: any
  auth_id: string
  auth_provider: string
  created_at: string
  updated_at: string
  metadata: Record<string, any>
  shopify_cart_id: string
  ai_clone: any
}

export class UserService {
  private static instance: UserService

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService()
    }

    return UserService.instance
  }

  /**
   * Get the current user information
   */
  async me() {
    const axios = getAxiosInstance()
    const req = await axios.get<User>('/users/me')
    return req.data
  }

  async adminMe() {
    const axios = getAxiosInstance()
    const req = await axios.get<User>('/users/admin/me')
    return req.data
  }

  /**
   * Create a guest user
   * @todo this temporary solution should be removed once we have a proper auth flow
   * @returns
   */
  async createGuestUser() {
    const axios = getAxiosInstance()
    const id = uuid()
    const req = await axios.get<User>(`/users/guest/${id}`)

    return req.data
  }

  async getGuestUser(guest_id: string) {
    const axios = getAxiosInstance()
    const req = await axios.get<User>(`/users/guest/${guest_id}`)
    return req.data
  }

  /**
   * Update the guest user to a real user
   */
  async updateGuestUser(guest_id: string, user_data: User) {
    const axios = getAxiosInstance()
    const req = await axios.patch<User>(`/users/guest/${guest_id}`, user_data)
    return req.data
  }
}

export const userService = UserService.getInstance()
