import {useCallback, useState} from 'react'
import {v4 as uuid} from 'uuid'

import {ProductFinderResponse} from '@atorie/api/product-finder'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'

import {useAuthUser} from '../use-auth-user'
import {useProductFinderMutation} from './use-product-finder.mutation'

export interface MultiProductFinderMutationOptions
  extends Omit<
    UseMutationOptions<
      PromiseSettledResult<ProductFinderResponse>[],
      Error,
      {
        files: FileList | File[]
      }
    >,
    'mutationFn'
  > {}

export function useMultiProductsFinderMutation({
  onSuccess,
  ...opts
}: MultiProductFinderMutationOptions = {}) {
  const {user, isLoading} = useAuthUser()
  const productFinderMutation = useProductFinderMutation({})
  const [count, setCount] = useState(0)
  const [progress, setProgress] = useState(0)

  const updateProgress = useCallback(() => {
    setProgress((prev) => {
      return prev + 1
    })
  }, [])
  const updateCount = useCallback((count: number) => {
    setCount(count)
  }, [])

  const {
    reset: multiProductsFinderMutationReset,
    ...multiProductsFinderMutation
  } = useMutation({
    async mutationFn({files}) {
      const filesArr = files instanceof FileList ? Array.from(files) : files
      updateCount(filesArr.length)

      if (user == null || isLoading) {
        throw new Error('User is not authenticated')
      }

      const allFilesPromise = await Promise.allSettled(
        filesArr.map(async (file) => {
          const res = await productFinderMutation.mutateAsync({
            imageUrl: file,
            userId: user.id,
            requestId: uuid(),
          })

          updateProgress()
          return res
        }),
      )

      const allHasError = allFilesPromise.every(
        (promise) => promise.status === 'rejected',
      )

      if (allHasError) {
        throw new Error('All files failed')
      }

      return allFilesPromise
    },
    onSuccess(...args) {
      onSuccess?.(...args)
    },
    ...opts,
  })

  const reset = useCallback(() => {
    setProgress(0)
    setCount(0)
    multiProductsFinderMutationReset()
  }, [multiProductsFinderMutationReset])

  return {
    ...multiProductsFinderMutation,
    reset,
    count,
    progress,
  }
}
