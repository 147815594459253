/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../types/API'

type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType
  __generatedSubscriptionOutput: OutputType
}

export const onCreateProductFinderRequest =
  /* GraphQL */ `subscription OnCreateProductFinderRequest(
  $filter: ModelSubscriptionProductFinderRequestFilterInput
) {
  onCreateProductFinderRequest(filter: $filter) {
    id
    user_id
    status
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateProductFinderRequestSubscriptionVariables,
    APITypes.OnCreateProductFinderRequestSubscription
  >
export const onUpdateProductFinderRequest =
  /* GraphQL */ `subscription OnUpdateProductFinderRequest(
  $filter: ModelSubscriptionProductFinderRequestFilterInput
) {
  onUpdateProductFinderRequest(filter: $filter) {
    id
    user_id
    status
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateProductFinderRequestSubscriptionVariables,
    APITypes.OnUpdateProductFinderRequestSubscription
  >
export const onDeleteProductFinderRequest =
  /* GraphQL */ `subscription OnDeleteProductFinderRequest(
  $filter: ModelSubscriptionProductFinderRequestFilterInput
) {
  onDeleteProductFinderRequest(filter: $filter) {
    id
    user_id
    status
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteProductFinderRequestSubscriptionVariables,
    APITypes.OnDeleteProductFinderRequestSubscription
  >
export const onCreateProductIdForUrl =
  /* GraphQL */ `subscription OnCreateProductIdForUrl(
  $filter: ModelSubscriptionProductIdForUrlFilterInput
) {
  onCreateProductIdForUrl(filter: $filter) {
    url
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateProductIdForUrlSubscriptionVariables,
    APITypes.OnCreateProductIdForUrlSubscription
  >
export const onUpdateProductIdForUrl =
  /* GraphQL */ `subscription OnUpdateProductIdForUrl(
  $filter: ModelSubscriptionProductIdForUrlFilterInput
) {
  onUpdateProductIdForUrl(filter: $filter) {
    url
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateProductIdForUrlSubscriptionVariables,
    APITypes.OnUpdateProductIdForUrlSubscription
  >
export const onDeleteProductIdForUrl =
  /* GraphQL */ `subscription OnDeleteProductIdForUrl(
  $filter: ModelSubscriptionProductIdForUrlFilterInput
) {
  onDeleteProductIdForUrl(filter: $filter) {
    url
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteProductIdForUrlSubscriptionVariables,
    APITypes.OnDeleteProductIdForUrlSubscription
  >
export const onCreateSavedProduct =
  /* GraphQL */ `subscription OnCreateSavedProduct(
  $filter: ModelSubscriptionSavedProductFilterInput
) {
  onCreateSavedProduct(filter: $filter) {
    user_id
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateSavedProductSubscriptionVariables,
    APITypes.OnCreateSavedProductSubscription
  >
export const onUpdateSavedProduct =
  /* GraphQL */ `subscription OnUpdateSavedProduct(
  $filter: ModelSubscriptionSavedProductFilterInput
) {
  onUpdateSavedProduct(filter: $filter) {
    user_id
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateSavedProductSubscriptionVariables,
    APITypes.OnUpdateSavedProductSubscription
  >
export const onDeleteSavedProduct =
  /* GraphQL */ `subscription OnDeleteSavedProduct(
  $filter: ModelSubscriptionSavedProductFilterInput
) {
  onDeleteSavedProduct(filter: $filter) {
    user_id
    product_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteSavedProductSubscriptionVariables,
    APITypes.OnDeleteSavedProductSubscription
  >
export const onCreateVisionSearchEntry =
  /* GraphQL */ `subscription OnCreateVisionSearchEntry(
  $filter: ModelSubscriptionVisionSearchEntryFilterInput
) {
  onCreateVisionSearchEntry(filter: $filter) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateVisionSearchEntrySubscriptionVariables,
    APITypes.OnCreateVisionSearchEntrySubscription
  >
export const onUpdateVisionSearchEntry =
  /* GraphQL */ `subscription OnUpdateVisionSearchEntry(
  $filter: ModelSubscriptionVisionSearchEntryFilterInput
) {
  onUpdateVisionSearchEntry(filter: $filter) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateVisionSearchEntrySubscriptionVariables,
    APITypes.OnUpdateVisionSearchEntrySubscription
  >
export const onDeleteVisionSearchEntry =
  /* GraphQL */ `subscription OnDeleteVisionSearchEntry(
  $filter: ModelSubscriptionVisionSearchEntryFilterInput
) {
  onDeleteVisionSearchEntry(filter: $filter) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteVisionSearchEntrySubscriptionVariables,
    APITypes.OnDeleteVisionSearchEntrySubscription
  >
export const onCreateStylistRoom =
  /* GraphQL */ `subscription OnCreateStylistRoom(
  $filter: ModelSubscriptionStylistRoomFilterInput
) {
  onCreateStylistRoom(filter: $filter) {
    id
    name
    createdAt
    updatedAt
    users {
      nextToken
      __typename
    }
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateStylistRoomSubscriptionVariables,
    APITypes.OnCreateStylistRoomSubscription
  >
export const onUpdateStylistRoom =
  /* GraphQL */ `subscription OnUpdateStylistRoom(
  $filter: ModelSubscriptionStylistRoomFilterInput
) {
  onUpdateStylistRoom(filter: $filter) {
    id
    name
    createdAt
    updatedAt
    users {
      nextToken
      __typename
    }
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateStylistRoomSubscriptionVariables,
    APITypes.OnUpdateStylistRoomSubscription
  >
export const onDeleteStylistRoom =
  /* GraphQL */ `subscription OnDeleteStylistRoom(
  $filter: ModelSubscriptionStylistRoomFilterInput
) {
  onDeleteStylistRoom(filter: $filter) {
    id
    name
    createdAt
    updatedAt
    users {
      nextToken
      __typename
    }
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteStylistRoomSubscriptionVariables,
    APITypes.OnDeleteStylistRoomSubscription
  >
export const onCreateUserStylistRoom =
  /* GraphQL */ `subscription OnCreateUserStylistRoom(
  $filter: ModelSubscriptionUserStylistRoomFilterInput
) {
  onCreateUserStylistRoom(filter: $filter) {
    id
    userId
    stylistRoomId
    joinedAt
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateUserStylistRoomSubscriptionVariables,
    APITypes.OnCreateUserStylistRoomSubscription
  >
export const onUpdateUserStylistRoom =
  /* GraphQL */ `subscription OnUpdateUserStylistRoom(
  $filter: ModelSubscriptionUserStylistRoomFilterInput
) {
  onUpdateUserStylistRoom(filter: $filter) {
    id
    userId
    stylistRoomId
    joinedAt
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateUserStylistRoomSubscriptionVariables,
    APITypes.OnUpdateUserStylistRoomSubscription
  >
export const onDeleteUserStylistRoom =
  /* GraphQL */ `subscription OnDeleteUserStylistRoom(
  $filter: ModelSubscriptionUserStylistRoomFilterInput
) {
  onDeleteUserStylistRoom(filter: $filter) {
    id
    userId
    stylistRoomId
    joinedAt
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteUserStylistRoomSubscriptionVariables,
    APITypes.OnDeleteUserStylistRoomSubscription
  >
export const onCreateStylistRoomEntry =
  /* GraphQL */ `subscription OnCreateStylistRoomEntry(
  $filter: ModelSubscriptionStylistRoomEntryFilterInput
) {
  onCreateStylistRoomEntry(filter: $filter) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateStylistRoomEntrySubscriptionVariables,
    APITypes.OnCreateStylistRoomEntrySubscription
  >
export const onUpdateStylistRoomEntry =
  /* GraphQL */ `subscription OnUpdateStylistRoomEntry(
  $filter: ModelSubscriptionStylistRoomEntryFilterInput
) {
  onUpdateStylistRoomEntry(filter: $filter) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateStylistRoomEntrySubscriptionVariables,
    APITypes.OnUpdateStylistRoomEntrySubscription
  >
export const onDeleteStylistRoomEntry =
  /* GraphQL */ `subscription OnDeleteStylistRoomEntry(
  $filter: ModelSubscriptionStylistRoomEntryFilterInput
) {
  onDeleteStylistRoomEntry(filter: $filter) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteStylistRoomEntrySubscriptionVariables,
    APITypes.OnDeleteStylistRoomEntrySubscription
  >
